<template>
  <div class="box" style="background: #fff;" v-if="myInfo!=null">
    <van-tabs v-model="active" :sticky="true" :color="'#2d83e8'" :offset-top="0" @change="tabsChange">
      <van-tab title="未回复">
        <div class="list-box">
          <div v-for="item in postsList" :key="item.id">
            <div class="item-top-box">
              <img :src="item.users.headimgurl" style="width:40px;height:40px;border-radius: 5px;" alt="">
              <div style="width:100%;">
                <div style="margin-left:10px;">
                  <div style="color:#485884;font-size:16px;font-weight: 500;">{{item.users.name}}&nbsp;<van-tag :type="item.status == '1' ? 'warning':(item.status == '2' ? 'primary': 'success')">{{item.status == '1' ? '待回复':(item.status == '2' ? '已回复': '已解决')}}</van-tag></div>
                  <div style="font-size:16px;margin-top:3px;">{{item.content}}</div>
                </div>
                <div style="margin-left:10px;position: relative;margin-top: 10px;;">
                    <img style="width:80px;height: 80px;" class="pic-sty" v-for="(ite,indexpic) in formatPicUrl(item.pic)" :key="indexpic" :src="ite" alt="" @click="showPic(ite)">
                </div>
                <div style="margin-left:10px;color:#AFAFAF;font-size: 12px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;position: relative;user-select:none">
                  <!-- <div>8分钟前</div> -->
                  <!-- <div class="pl" @click="showdzpl(indexpos,item)">
                    <span></span>
                    <span></span>
                  </div> -->
                  <!-- <div class="dzpl" :class="[item.isshow?'open':'close']" v-if="item.isshow">
                    <div @click="addLikes(item)" v-if="isLikes==null">点赞</div>
                    <div @click="delLikes(item)" v-else>取消</div>
                    <div class="slines"></div>
                    <div @click="showPlPopup(item)">评论</div>
                  </div> -->
                </div>
                <div style="margin-left:10px;margin-top: 10px;background: #F7F7F7;padding: 5px 7px;border-radius: 5px;display: flex;color:rgb(72, 88, 132);flex-wrap: wrap;user-select:none">
                  <!-- <span><img style="width:12px;margin-top:3px;" src="./like.png" alt=""></span> -->
                  <!-- <span v-for="likes in item.likes" :key="likes.id">
                    <span style="color:rgb(72, 88, 132);font-size: 14px;font-weight: 500;">
                      &nbsp;{{likes.users.name}},
                    </span>
                  </span>
                  <div class="commslines" v-if="item.comms.length!=0"></div> -->
                  <div style="width:100%;">
                    <div v-for="comms in item.comment" :key="comms.id" style="margin-bottom:3px;user-select:none" class="commshover">
                      <!-- <van-popover
                        v-model="comms.ischeck"
                        trigger="click"
                        :actions="actions"
                        @select="onSelect"
                      >
                        <template #reference>
                          <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                        <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                        </template>
                      </van-popover> -->
                      <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                      <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                    </div>
                    <div style="width:100%;display:flex;justify-content: flex-end;" v-if="item.comment.length==0">
                      <div class="cklx" @click="updateTsjy(item)">&nbsp;回复&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div class="lines"></div>
              </div>
            </div>
          </div>
          <!-- <div style="height:100px;"></div> -->
        </div>
      </van-tab>
      <van-tab title="已回复">
        <div class="list-box">
          <div v-for="item in postsList" :key="item.id">
            <div class="item-top-box">
              <img :src="item.users.headimgurl" style="width:40px;height:40px;border-radius: 5px;" alt="">
              <div style="width:100%;">
                <div style="margin-left:10px;">
                  <div style="color:#485884;font-size:16px;font-weight: 500;">{{item.users.name}}&nbsp;<van-tag :type="item.status == '1' ? 'warning':(item.status == '2' ? 'primary': 'success')">{{item.status == '1' ? '未回复':(item.status == '2' ? '已回复': '已解决')}}</van-tag></div>
                  <div style="font-size:16px;margin-top:3px;">{{item.content}}</div>
                </div>
                <div style="margin-left:10px;position: relative;margin-top: 10px;;">
                    <img style="width:80px;height: 80px;" class="pic-sty" v-for="(ite,indexpic) in formatPicUrl(item.pic)" :key="indexpic" :src="ite" alt="" @click="showPic(ite)">
                </div>
                <div style="margin-left:10px;color:#AFAFAF;font-size: 12px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;position: relative;user-select:none">
                  <!-- <div>8分钟前</div> -->
                  <!-- <div class="pl" @click="showdzpl(indexpos,item)">
                    <span></span>
                    <span></span>
                  </div> -->
                  <!-- <div class="dzpl" :class="[item.isshow?'open':'close']" v-if="item.isshow">
                    <div @click="addLikes(item)" v-if="isLikes==null">点赞</div>
                    <div @click="delLikes(item)" v-else>取消</div>
                    <div class="slines"></div>
                    <div @click="showPlPopup(item)">评论</div>
                  </div> -->
                </div>
                <div style="margin-left:10px;margin-top: 10px;background: #F7F7F7;padding: 5px 7px;border-radius: 5px;display: flex;color:rgb(72, 88, 132);flex-wrap: wrap;user-select:none">
                  <!-- <span><img style="width:12px;margin-top:3px;" src="./like.png" alt=""></span> -->
                  <!-- <span v-for="likes in item.likes" :key="likes.id">
                    <span style="color:rgb(72, 88, 132);font-size: 14px;font-weight: 500;">
                      &nbsp;{{likes.users.name}},
                    </span>
                  </span>
                  <div class="commslines" v-if="item.comms.length!=0"></div> -->
                  <div style="width:100%;">
                    <div v-for="comms in item.comment" :key="comms.id" style="margin-bottom:3px;user-select:none" class="commshover">
                      <!-- <van-popover
                        v-model="comms.ischeck"
                        trigger="click"
                        :actions="actions"
                        @select="onSelect"
                      >
                        <template #reference>
                          <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                        <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                        </template>
                      </van-popover> -->
                      <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                      <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                    </div>
                    
                  </div>
                </div>
                <div class="lines"></div>
              </div>
            </div>
          </div>
          <div style="height:100px;"></div>
        </div>
      </van-tab>
    </van-tabs>

    <van-popup v-model="showTishi" position="bottom" round>
        <div style="width: 100%;">
          <div style="text-align:center;margin:15px 0;font-size:16px;">回复投诉建议</div>
          <textarea placeholder="请输入回复" class="texta" v-model="content"></textarea>
          <div class="btn-box">
            <div class="cklx2" @click="sureShbtg">确定回复</div>
          </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { ImagePreview,Toast,Dialog,Tag,Tab, Tabs,Popup } from "vant";
// import Empty from "./comm/empty.vue";
// import Loading from "./comm/loading.vue";
import { question } from "@/api";
import dayjs from 'dayjs'
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import Config from "@/config";
export default {
  components: {
    // Empty: Empty,
    // Loading: Loading,
    VanTag: Tag,
    VanTab: Tab,
    VanTabs: Tabs,
    VanPopup: Popup,
    // VanImagePreview: ImagePreview,
    // VanPopup: Popup,
    // VanPopover: Popover,
  },
  data() {
    return {
      active: '',
      showCommsPopup: false,
      postsList: [],
      content:"",
      myInfo: null,
      postsInfo: null,
      isLikes: null,
      commsInfo: null,
      showPopover: [],
      showTishi: false,
      actions: [{ text: '删除' }],
    };
  },

  mounted() {
    document.title = "处理投诉建议";
    // window.addEventListener('mousedown',this.handleonmousedown)
    this.myInfo = JSON.parse(localStorage.getItem("user"))
    this.getPassengerTripList(0);

  },
  methods: {
    sureShbtg() {
      if(this.content == '') {
        Toast.fail('请输入回复内容')
        return
      }
      let params = {
        questionId: this.postsInfo.id,
        content: this.content
      }
      question.addQuestionComm(params).then(res=> {
        if(res.data.code == 200) {
          Toast.success('回复成功')
          this.content = ''
          this.showTishi = false
          this.getPassengerTripList(0);
        }
      })
    },
    tabsChange(e) {
      console.log(e)
      this.getPassengerTripList(e);
    },
    delQuestion(e) {
      Dialog.confirm({
            title: '提醒',
            message: '确定删除吗？',
          })
            .then(() => {
              // on confirm
              let params = {
                id: e.id
              }
              question.delQuestion(params).then(()=>{
                Toast.success('删除成功');
                this.getPassengerTripList()
              })
            })
            .catch(() => {
              // on cancel
            });
    },
    addTsjy() {
      this.$router.push({
        name: 'add-question',
        query: {
          isAdd: 'ADD'
        }
      })
    },
    updateTsjy(e) {
      this.postsInfo = e
      this.showTishi = true
    },
    onSelect() {},
    showDeleteButton(e,k,l) {
      console.log(e)
      console.log(l)
        if(k.userId == this.myInfo.id) {
          clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
          this.Loop = setTimeout(function() {
            this.commsInfo = k
            Dialog.confirm({
            title: '提醒',
            message: '确定删除评论吗？',
          })
            .then(() => {
              // on confirm
              let params = {
                id: k.id
              }
              question.delComms(params).then(()=>{
                Toast.success('删除成功');
                this.getPassengerTripList()
              })
            })
            .catch(() => {
              // on cancel
            });
          }.bind(this),1000);
        }
    },
    clearLoop() {
        clearTimeout(this.Loop);
    },
    showPlPopup(e) {
      this.postsInfo = e
      this.showCommsPopup = true
    },
    sureComms() {
      if(this.content == "") {
        Toast.fail('请输入评论');
        return
      }
      let params = {
        id: this.postsInfo.id,
        content: this.content
      }
      question.addComms(params).then(()=>{
        this.showCommsPopup = false
        this.content = ""
        this.getPassengerTripList();
      })
    },
    addLikes(e) {
      let params = {
        id: e.id
      }
      question.addLikes(params).then(()=>{
        this.getPassengerTripList();
      })
    },
    delLikes(e) {
      let params = {
        id: e.id
      }
      question.delLikes(params).then(()=>{
        this.getPassengerTripList();
      })
    },
    getLikesed(e) {
      let params = {
        id: e.id
      }
      question.getLikesed(params).then(res=>{
        this.isLikes = res.data.data
      })
    },
    // handleonmousedown(){
    //   for(let i in this.postsList) {
    //     this.postsList[i].isshow = false
    //   }
    // },
    showdzpl(e,k) {
      console.log(e)
      for(let i in this.postsList) {
        this.postsList[i].isshow = false
      }
      this.getLikesed(k)
      this.postsList[e].isshow = true
    },
    showPic(e) {
      console.log(e)
      ImagePreview([e]);
    },
    formatPicUrl(e) {
      let lstring = []
      if(!e) {
        return []
      }
      let ls = e.split(',')
      for(let i in ls) {
        lstring.push(Config.resource.base + ls[i])
      }
      console.log()
      return lstring
    },
    getPassengerTripList(e) {
      this.isShowLoading = true;
      let params = {
        status: e == 0 ? '1' : '2',
      };
      question.getAllQuestionList(params).then((res) => {
        this.postsList = res.data.data
        this.isShowLoading = false;
      });
    },
  },
};
</script>
<style scoped>
/deep/ .van-tabs--line .van-tabs__wrap {
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
}
.box{position: relative;}
.box::-webkit-scrollbar{width: 0 !important;}
.list-box {width: 92%;margin-left: 4%;margin-top: 15px;overflow: auto;}
.item-top-box {display: flex; align-items: flex-start;}
.pic-sty {object-fit: cover;margin-right: 5px;;}
.lines {height: 1px;width: 100%;background: #f2f2f2;margin: 10px 0 15px 0;}
.commslines{height: 1px;width: 100%;background: #F0F0F0;margin: 4px 0 7px 0;}
.myinfo {position: absolute;display: flex;align-items: center;top: 140px;right: 15px;color:#fff;}
.pl{height: 19px;width: 29px;background: #F7F7F7;border-radius: 3px;display: flex;align-items: center;justify-content:center;}
.pl span{height: 3px;width: 3px;background: #4F5F89;border-radius: 50%;margin: 0 2px;}
.dzpl {width:90px;height:17px;position: absolute;display: flex;top: -6px;right: 35px;background: #000;padding: 7px 15px;border-radius: 5px;align-items: center;justify-content: space-around;}
.dzpl div{color: #fff;}
.dzpl div:nth-child(1){width: 35px;text-align: center;white-space: nowrap;}
.dzpl div:nth-child(3){width: 35px;text-align: center;white-space: nowrap;}
.slines{width: 1px;height: 15px;background: #fff;margin: 0 15px;}
.close {animation: right 0.1s linear both;}
.open {animation: left 0.1s linear both;}
.btn-box{width: 90%;margin-left:5%; display: flex;align-items: center;justify-content: space-between;}
.texta {width: 85%;margin-left: 5%;font-size: 16px;height: 120px;padding: 10px;border: 1px solid #f2f2f2;border-radius: 10px;resize: none;margin-top: 10px;}
.cklx1 {width: 90%;margin-left:5%;height: 45px;color:#fff;background: #2d83e8;border-radius: 20px;font-size: 18px;line-height: 45px;text-align: center;margin-top: 20px;position: fixed; bottom: 15px;;}
.cklx2 {width: 100%;height: 45px;color:#fff;background: #2d83e8;border-radius: 20px;font-size: 18px;line-height: 45px;text-align: center;margin-top: 20px;margin-bottom:20px;}
.commshover:active {background: rgb(175, 175, 175);border-radius: 3px;;}
.cklx {border: 1px solid #2d83e8;padding: 5px 10px;border-radius: 20px;font-size: 12px;color: #2d83e8;}
@keyframes right {
        from {
            width: 90px;
        }
 
        to {
            width: 0px;
        }
    }
@keyframes left {
        from {
            width: 0px;
        }
 
        to {
            width: 90px;
        }
    }
</style>
